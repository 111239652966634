import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from './assets/logo.png';
import { faUsers, faCartShopping, faDroplet } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function CustomNavbar() {
const navbarItems = [
    { label: 'Chi siamo', url: '/about', icon: faUsers },
    { label: 'Autowash', url: '/autowash', icon: faDroplet },
    { label: 'Stock', url: '/stock', icon: faCartShopping },
];

    return (
        <>
            <Navbar fixed="top" expand="lg" className="bg-light">
                <Container>
                    <Navbar.Brand href="/">
                        <img className='navbarLogo' src={logo}/>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            {navbarItems.map((item, index) => (
                                <span className='container d-flex justify-content-start align-items-center ps-lg-3 py-3 py-lg-0'>
                                    <FontAwesomeIcon icon={item.icon}></FontAwesomeIcon>
                                    <Nav.Link className='textc-black ps-2 no-string-break' key={index} href={item.url}>{item.label}</Nav.Link>
                                </span>
                            ))}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}

export default CustomNavbar;