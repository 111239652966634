import React from 'react';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import michele from './assets/michele.jpeg';
import adriano from './assets/adriano.jpeg';

const About = () => {
return (
    <Container className='py-5'>
        <Row className="justify-content-center mb-4">
            <Col md={8} className="text-center">
            <p className="lead opacity-75">
                CTM Servizi, fondata da Michele Cirillo nel 2010, è un'azienda leader nel settore dell' autolavaggio automatico con oltre trent'anni di esperienza.
            </p>
            </Col>
        </Row>
        <Row className="mb-5">
            <Col md={4}>
                <div className='ratio ratio-1x1'>
                    <Image className='object-fit-cover' src={michele} rounded fluid />
                </div>
            </Col>
            <Col md={8} className="d-flex align-items-center">
            <div>
                <h2 className='display-6 fw-bold mt-2 mt-md-0'>La nascita dell'Azienda</h2>
                <p className='opacity-75 lead'>
                Grazie alla vasta esperienza di Michele Cirillo nel settore elettrico industriale, CTM Servizi è diventata un punto di riferimento per soluzioni innovative e tecnologiche nel settore del lavaggio auto automatico.
                </p>
            </div>
            </Col>
        </Row>

        <Row className="mb-5">
            <Col md={8} className="d-flex align-items-center order-2 order-md-1">
            <div>
                <h2 className='display-6 fw-bold mt-2 mt-md-0 text-md-end'>Innovazione e Crescita</h2>
                <p className='text-md-end opacity-75 lead'>
                Con l'ingresso di Adriano Cirillo, "Vision executive manager", l'azienda ha avviato un percorso di digitalizzazione e miglioramento continuo, implementando nuove soluzioni tecnologiche.
                </p>
            </div>
            </Col>
            <Col md={4} className="order-1 order-md-2">
                <div className='ratio ratio-1x1'>
                    <Image className='object-fit-cover' src={adriano} rounded fluid />
                </div>
            </Col>
        </Row>

        <Row className="mb-5">
            <Col md={12} className="text-center">
            <h2 className='display-6 fw-bold'>Collaborazione con Schneider Electric</h2>
            <p className='lead opacity-75'>
                Dal 2023, CTM Servizi collabora con Schneider Electric per la creazione di soluzioni innovative per il controllo remoto degli impianti, migliorando l'efficienza e riducendo i tempi di fermo.
            </p>
            </Col>
        </Row>

        <Row>
            <Col md={6}>
            <Card className="shadow-sm mb-4">
                <Card.Body>
                <Card.Title>Progettazione e Testing</Card.Title>
                <Card.Text>
                    Il processo di progettazione e testing interno dell'azienda garantisce la qualità e l'affidabilità dei prodotti, riducendo i tempi di intervento presso i clienti.
                </Card.Text>
                </Card.Body>
            </Card>
            </Col>
            <Col md={6}>
            <Card className="shadow-sm mb-4">
                <Card.Body>
                <Card.Title>Obiettivi Futuri</Card.Title>
                <Card.Text>
                    Il nostro obiettivo è consolidare la nostra posizione nel mercato e continuare a innovare in linea con i principi dell'industria 4.0.
                </Card.Text>
                </Card.Body>
            </Card>
            </Col>
        </Row>
    </Container>
);
}

export default About;
