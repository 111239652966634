import Container from 'react-bootstrap/Container';
import car from './assets/car.jpg';
import plc from './assets/plc.jpg';
import { faBarcode, faUser, faCubes, faCalendarDay, faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';

function PictureSection({image, orientation}) {
    return (
        <div data-aos="fade-up" data-aos-delay="200" className={`col-12 col-lg-6 px-0 ${orientation ? "pe-lg-5" : "ps-lg-5 mt-4 mt-lg-0"} d-flex flex-column`}>
            <img src={image} className="img-fluid rounded-4 object-fit-cover" alt="Profile" style={{ height: '100%' }}></img>
        </div>
    );
}

function TextSection({title, content, href}) {
    return (
        <div className="col-12 col-lg-6 px-0 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
            <h6 data-aos="fade-up" data-aos-delay="200" className="display-6 fw-bolder underline w-max mb-3">{title}</h6>
            <p data-aos="fade-up" data-aos-delay="400" className="readability my-0 lead opacity-75">
                {content}
            </p>
            <Link to={href} data-aos="fade-up" data-aos-delay="200" className='d-block w-max bg-accent text-light fw-bolder rounded-2 text-light py-2 mt-3 px-3 text-decoration-none'>Leggi di più</Link>
        </div>
    );
}

function Qualities() {
    return (
        <div className='d-flex flex-wrap justify-content-between pt-5'>
            <div className='col-12 col-lg-3 d-flex flex-column justify-content-start align-items-center rounded-2 p-5'>
                <div className='text-center'>
                    <FontAwesomeIcon icon={faBarcode} className='text-accent fw-bold mb-2 display-4 textc-accent' />
                    <p className='lead mt-2'>Qualità dei Prodotti</p>
                </div>
                <p className='small text-center opacity-75'>I prodotti che scegliamo per voi sono gli stessi che utilizziamo noi per la nostra attività. Testiamo la qualità, l’affidabilità e la condividiamo con i clienti che ci hanno scelto.</p>
            </div>
            <div className='col-12 col-lg-3 d-flex flex-column justify-content-center align-items-center rounded-2 p-5'>
                <div className='text-center'>
                    <FontAwesomeIcon icon={faUser} className='text-accent fw-bold mb-2 display-4 textc-accent' />
                    <p className='lead mt-2'>Consulente Dedicato</p>
                </div>
                <p className='small text-center opacity-75'>Scegliere noi vuol dire anche avere un consulente dedicato che ti guiderà tra la scelta dei prodotti, proponendoti le offerte migliori, il tutto con un dialogo diretto e senza filtri.</p>
            </div>
            <div className='col-12 col-lg-3 d-flex flex-column justify-content-center align-items-center rounded-2 p-5'>
                <div className='text-center'>
                    <FontAwesomeIcon icon={faCubes} className='text-accent fw-bold mb-2 display-4 textc-accent' />
                    <p className='lead mt-2'>Supporto / Montaggio</p>
                </div>
                <p className='small text-center opacity-75'>La nostra esperienza sarà a vostra disposizione, dispensando consigli utili per il montaggio ed installazione dei prodotti scelti. Nessuna domanda è mai vana, troviamo insieme le soluzioni.</p>
            </div>
        </div>
    );
}

function ShopHoursTable() {
    const today = (new Date().getDay() + 6) % 7;
    const currentHour = new Date().getHours();
    const currentMinute = new Date().getMinutes();

    const isOpen = (hours) => {
        if (hours === 'CHIUSO') return false;
        const [openTime, closeTime] = hours.split(' - ').map(time => {
            const [hour, minute] = time.split(':').map(Number);
            return { hour, minute };
        });

        const now = currentHour * 60 + currentMinute;
        const open = openTime.hour * 60 + openTime.minute;
        const close = closeTime.hour * 60 + closeTime.minute;

        return now >= open && now <= close;
    };

    return (
        <div className='my-5'>
            <h6 data-aos="fade-up" data-aos-delay="200" className="display-6 ps-0 fw-bolder mb-3">La nostra tabella oraria</h6>
            <Table striped hover className='rounded-4 mb-5'>
                <thead>
                    <tr>
                        <th className='text-light bg-accent'><FontAwesomeIcon icon={faCalendarDay} /></th>
                        <th className='text-light bg-accent'><FontAwesomeIcon icon={faClock} /></th>
                    </tr>
                </thead>
                <tbody>
                    {[
                        { day: 'Lunedì', hours: '09:00 - 18:00' },
                        { day: 'Martedì', hours: '09:00 - 18:00' },
                        { day: 'Mercoledì', hours: '09:00 - 18:00' },
                        { day: 'Giovedì', hours: '09:00 - 18:00' },
                        { day: 'Venerdì', hours: '09:00 - 18:00' },
                        { day: 'Sabato', hours: 'CHIUSO' },
                        { day: 'Domenica', hours: 'CHIUSO' },
                    ].map((day, index) => {
                        const isCurrentDay = today === index;
                        const isCurrentlyOpen = isOpen(day.hours);

                        const BackgroundColor = ((isCurrentDay && !isCurrentlyOpen))
                            ? 'rgba(255, 0, 0, 0.12)'
                            : (isCurrentDay && isCurrentlyOpen ? 'rgba(0, 255, 0, 0.12)' : 'inherit');

                        return (
                            <tr key={index}>
                                <td className='fw-bold' style={{ backgroundColor: BackgroundColor }}>
                                    {day.day}
                                </td>
                                <td style={{ backgroundColor: BackgroundColor }}>
                                    {day.hours}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );
}



function AboutComponent() {
    return (
        <>
            <Container className='px-4 px-lg-0'>
                <div id='about' className='row flex-wrap py-5'>
                    <PictureSection orientation={true} image={car}></PictureSection>
                    <TextSection href={"/about"} title={"Su di noi..."} content={"CTM Servizi, fondata da Michele Cirillo nel 2010, ha oltre 30 anni di esperienza nel settore elettrico industriale. Specializzata nel lavaggio auto automatico, è diventata un punto di riferimento in Italia, Svizzera e Francia. Con l'ingresso del figlio Adriano, l'azienda ha avviato un processo di digitalizzazione, culminato nel 2023 con una collaborazione innovativa con Schneider Electric per il controllo remoto degli impianti. CTM mira a semplificare e migliorare l'efficienza degli impianti, con l'obiettivo di espandersi e innovare secondo i principi dell'industria 4.0. Scopri di più sul loro percorso di crescita e innovazione."}></TextSection>
                    <Qualities></Qualities>
                    <ShopHoursTable></ShopHoursTable>
                    <TextSection href={"/revamp"} title={"Revamping dei PLC"} content={"Il revamping dei PLC rinnova gli impianti degli anni '90, riducendo sprechi, permettendo il controllo remoto e gestendo criticità. Grazie agli upgrade dei circuiti, si ottiene il monitoraggio in tempo reale di parametri essenziali come conducibilità e durezza dell'acqua, per massima efficienza e sostenibilità."}></TextSection>
                    <PictureSection orientation={false} image={plc}></PictureSection>
                </div>
            </Container>
        </>
    );
}

export default AboutComponent;