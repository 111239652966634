import './App.css';

import CustomNavbar from './CustomNavbar.js';
import HeaderComponent from './HeaderComponent.js';
import FooterComponent from './FooterComponent.js';
import AboutComponent from './AboutComponent.js';
import StockComponent from './StockComponent.js';
import ScrollToTop from './ScrollToTop.js';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Autowash from './Autowash.js';
import PlcPage from './PlcPage.js';
import About from './About.js';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <CustomNavbar></CustomNavbar>
      <Routes>
        <Route path="/" element={
          <>
            <HeaderComponent
              headerContent={[
                { title: 'CTM Servizi', subtitle: "Soluzioni per piacere", href: "mailto:ctm.servizi@gmail.com", cta: "Richiedi Informazioni" }
              ]}
            />
            <AboutComponent></AboutComponent>
          </>
        } />
        <Route path="/stock" element={
          <>
            <HeaderComponent
              headerContent={[
                { title: 'Il nostro stock', href: "mailto:ctm.servizi@gmail.com", cta: "Richiedi Informazioni" }
              ]}
            />
            <StockComponent></StockComponent>
          </>
        } />
        <Route path="/autowash" element={
          <>
            <HeaderComponent
              headerContent={[
                { title: 'Autowash', href: "mailto:ctm.servizi@gmail.com", cta: "Richiedi Informazioni" }
              ]}
            />
            <Autowash></Autowash>
          </>
        } />
        <Route path="/revamp" element={
          <>
            <HeaderComponent
              headerContent={[
                { title: 'Revamping Plc & Upgrade circuiti', href: "mailto:ctm.servizi@gmail.com", cta: "Richiedi Informazioni" }
              ]}
            />
            <PlcPage></PlcPage>
          </>
        } />
        <Route path="/about" element={
          <>
            <HeaderComponent
              headerContent={[
                { title: 'Chi siamo', href: "mailto:ctm.servizi@gmail.com", cta: "Richiedi Informazioni" }
              ]}
            />
            <About></About>
          </>
        } />
      </Routes>
      <FooterComponent></FooterComponent>
    </Router>      
  );
}

export default App;
