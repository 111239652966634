import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import st1 from './assets/st (1).jpg';
import st2 from './assets/st (2).jpg';
import st3 from './assets/st (3).jpg';
import st4 from './assets/st (4).jpg';
import st5 from './assets/st (5).jpg';
import st6 from './assets/st (6).jpg';
import st7 from './assets/st (7).jpg';
import st8 from './assets/st (8).jpg';

const products = [
    {
        image: st1,
        title: "TSXDEY64D2K",
        description: "Modulo da 32 ingressi digitali Modicon Premium (Schneider) - 64 I - 24 V CC",
    },
    {
        image: st2,
        title: "TSXDEY32D2K",
        description: "Modulo da 32 ingressi digitali Modicon Premium (Schneider) - 32 I - 24 V CC",
    },
    {
        image: st3,
        title: "XBTP022110",
        description: "Monitor operativo di controllo - Telemecanique Modicon Magelis (Schneider)  - 24VDC",
    },
    {
        image: st4,
        title: "TSXDSY64T2K",
        description: "Modulo da 64 uscite digitali  stato solido Modicon Premium (Schneider) - 64 U - 24 V CC",
    },
    {
        image: st5,
        title: "TSXDSY32T2K",
        description: "Modulo da 32 uscite digitali  stato solido Modicon Premium (Schneider) - 32 U - 24 V CC",
    },
    {
        image: st6,
        title: "TSXP57103",
        description: "Processore PL7 in formato singolo - 440 mA V CC",
    },
    {
        image: st7,
        title: "TSXPSY2600",
        description: "Modulo alimentazione - 100...240 V CA - 300/500 mA - IP20",
    },
    {
        image: st8,
        title: "TSXRKY8",
        description: "Rack non estensibile per configurazioni con rack singoli da 8 slot - IP20",
    }
];

const ProductCard = ({ image, title, description }) => (
    <Col md={4} className='my-3'>
        <Card className='h-100'>
            <div className='ratio ratio-1x1'>
                <Card.Img className='ratio ratio-1x1' variant="top" src={image} />
            </div>
            <Card.Body className='d-flex flex-column justify-content-between'>
                <div>
                    <Card.Title>{title}</Card.Title>
                    <Card.Text className='mb-4'>{description}</Card.Text>
                </div>
                <div>
                    <Card.Text className='small mb-1'>Prodotto rigenerato,<br></br>Per maggiori informazioni:</Card.Text>
                    <Row>
                        <Col xs={3}>
                            <a href="https://wa.me/393458849165" className='d-block bg-success py-2 mt-2 text-center text-decoration-none text-light rounded-2'>
                                <FontAwesomeIcon icon={faWhatsapp} />
                            </a>
                        </Col>
                        <Col xs={9}>
                            <a href="mailto:ctm.servizi@gmail.com" className='d-block bg-warning py-2 mt-2 text-center text-decoration-none text-black rounded-2'>
                                <FontAwesomeIcon icon={faEnvelope} /> E-mail
                            </a>
                        </Col>
                    </Row>
                </div>
            </Card.Body>    
        </Card>
    </Col>
);

const FilterComponent = ({ setFilteredProducts }) => {
    const [priceRange, setPriceRange] = useState('');
    const [productName, setProductName] = useState('');
    const [sortOrder, setSortOrder] = useState('ascending');

    const handleFilter = () => {
        const [minPrice, maxPrice] = priceRange.split("-").map(Number);
        const nameFilter = productName.toLowerCase();

        const filtered = products.filter(product => {
            const price = parseFloat(product.price);
            const matchesPrice = !isNaN(minPrice) && !isNaN(maxPrice) ? price >= minPrice && price <= maxPrice : true;
            const matchesName = nameFilter ? product.title.toLowerCase().includes(nameFilter) : true;
            return matchesPrice && matchesName;
        });

        const sortedProducts = filtered.sort((a, b) =>
            sortOrder === 'ascending' ? a.price - b.price : b.price - a.price
        );

        setFilteredProducts(sortedProducts);
    };

    return (
        <Card.Footer className="text-center py-3 mb-3">
            <h3>Filtra per nome:</h3>
            <p className="small">Trova subito il prodotto che cerchi attraverso il nostro filtro di ricerca.</p>
            {/*<Form.Control
                className='my-2'
                type="text"
                placeholder="es: 400-1200 o 300..."
                value={priceRange}
                onChange={(e) => setPriceRange(e.target.value)}
            />*/}
            <Form.Control
                className='my-2'
                type="text"
                placeholder="Nome del prodotto..."
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
            />
            <Button className='w-100 bg-accent border-0' onClick={handleFilter}>
                <FontAwesomeIcon icon={faWandMagicSparkles} /> Filtra
            </Button>
            <p className="small fst-italic mt-3">Non è necessario inserire tutti i parametri di ricerca!</p>
        </Card.Footer>
    );
};

const StockComponent = () => {
    const [filteredProducts, setFilteredProducts] = useState(products);

    return (
        <Container className='my-5'>
            <p className='lead mb-5'>
                Questa sezione è stata creata per promuovere articoli di qualità a un prezzo eccezionale.<br />
                Le offerte in stock copriranno diversi settori e saranno aggiornate regolarmente. Rimanete collegati per cogliere le migliori occasioni durante l’anno.
            </p>
            <FilterComponent setFilteredProducts={setFilteredProducts} />
            <Row>
                {filteredProducts.length > 0 ? (
                    filteredProducts.map((product, index) => (
                        <ProductCard key={index} {...product} />
                    ))
                ) : (
                    <p>No products found.</p>
                )}
            </Row>
        </Container>
    );
};

export default StockComponent;
