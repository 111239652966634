import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

function FooterComponent() {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "//embed.typeform.com/next/embed.js";
        script.async = true;
        document.body.appendChild(script);
        
        return () => document.body.removeChild(script);
    }, []);

    const Headquarters = (
        <div className="col-12 col-md-6 pe-0 pe-md-5 ps-0 mb-5">
            <h6 data-aos="fade-up" className="display-6 fw-bolder w-max textc-black">Sede legale</h6>
            <p data-aos="fade-up" className="lead my-3 my-md-0 mt-md-3 mb-md-4">
                <span className='fw-bold no-string-break'>Via Gottardo, 89</span><br></br>
                <span className='small no-string-break'>10155 Torino, Italia</span>
            </p>
            <p data-aos="fade-up" className='lead mb-3 mb-md-0 textc-black'></p>
        </div>
    );

    const Contacts = (
        <div className="col-12 col-md-6 mb-5">
            <h6 data-aos="fade-up" className="display-6 fw-bolder w-max textc-black">Contatti</h6>
            <p data-aos="fade-up" className="lead my-3">
                <span className='fw-bold no-string-break'><FontAwesomeIcon icon={faEnvelope} /> ctm.servizi@gmail.com</span><br></br>
                <span className='small no-string-break'>+39 345-8849165</span>
            </p>
        </div>
    );

    const Socials = (
        <div className="col-12 mb-5 px-0">
            <h6 data-aos="fade-up" className="no-string-break display-6 fw-bolder w-max textc-black">Socials</h6>
            <p data-aos="fade-up" className="no-string-break lead my-3">
                <a className='textc-black text-decoration-none' target='_blank' href='https://www.facebook.com/CTMServizi/'><span className='fw-bold'><FontAwesomeIcon icon={faFacebook} /> CTM Servizi</span></a>
            </p>
        </div>
    );

    return (
        <footer className='pt-5 border-top border-4 border-black bg-black bg-opacity-10'>
            <Container data-aos="fade-up">
                <Row>
                    <Col lg={4}>
                    {Headquarters}
                    </Col>
                    <Col lg={4}>
                    {Contacts}
                    </Col>
                    <Col lg={4}>
                    {Socials}
                    </Col>                   
                </Row>
                <h6 className="display-6 px-0 fw-bolder w-max mb-3 textc-black">Form di contatto</h6>
                <div className='px-0' data-tf-live="01J6F1DW4QBSXEGAMPQ7KCS21A"></div>
                <p className="text-muted">Copyright© 2024 CTM Servizi. Tutti i diritti riservati.</p>
            </Container>
            <div className='bgc-black text-light text-center py-5 readability'>Sito web realizzato da Matteo Migliarese<br></br><span className='opacity-75'>migliaresematteo@gmail.com<br></br>Whatsapp: +39 339 644 6669</span></div>
        </footer>
    );
}

export default FooterComponent;
