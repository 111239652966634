import { Row, Col, Container} from "react-bootstrap"
import aw1 from './assets/autowash (1).jpeg';
import aw2 from './assets/autowash (2).jpeg'
import awf1 from './assets/awf (1).jpg';
import awf2 from './assets/awf (2).jpg';
import awf3 from './assets/awf (3).jpg';

function Card({ image, cardTitle, cardText, gallery, orientation }) {
    return (
        <div data-aos="fade-up" data-aos-offset="300" className={`row py-4 ${orientation ? "flex-row" : "flex-row-reverse"}`}>
            <div className="col-12 col-md-6">
                <img style={{ maxHeight: 400 }} alt="" src={image} className="rounded-2 img-fluid w-100 object-fit-cover"></img>
            </div>
            <div className={`col-12 col-md-6 d-flex flex-column justify-content-center ${orientation ? "align-items-md-start" : "align-items-md-end"}`}>
                <h1 className="textc-black mt-2">{cardTitle}</h1>
                <p className={`lead textc-black opacity-75 ${orientation ? "text-md-start" : "text-md-end"}`}>{cardText}</p>
            </div>
        </div>
    );
}

function TextSection({ text }) {
    return (
        <div className="py-5">
            <p className="lead opacity-75">{text}</p>
        </div>
    );
}

export default function Autowash() {
    return (
        <Container className="py-5">
            <Card 
                orientation={false} 
                image={aw1} 
                cardTitle={"La nostra storia"} 
                cardText={"La storia della nostra azienda ci posiziona al posto in Italia come società costruttrice e manutentrice di due noti marchi autowash self service franco-svizzeri che, dagli anni 90’ in poi, hanno portato la novità degli autolavaggi self service in Italia."} 
            />
            <Card 
                orientation={true} 
                image={aw2} 
                cardTitle={"La nostra tecnologia"} 
                cardText={"Questi autolavaggi si contraddistinguono per alcune caratteristiche di alta efficienza, tra le quali: un sistema di controllo Plc che gestisce e distribuisce tutti i circuiti, un’alta pressione di esercizio nelle sue fasi di lavaggio (120-130bar eccetto il prelavaggio), acqua calda nella fase di prelavaggio e lavaggio, sistemi addolcitore e osmosi industriali ad altissime prestazioni."} 
            />
            <TextSection 
                text={"Negli anni sono arrivati svariati nuovi sistemi di autolavaggio self service, ma la nostra azienda si è consolidata come costruttrice e manutentrice di una rete italiana che sfrutta la tecnologia sopra citata, la quale, secondo la nostra vision, è la più performante sul lungo periodo. Dal 2023 abbiamo inaugurato una nuova frontiera per aumentare ulteriormente le performance di questo sistema di lavaggio, ovvero il revamping del sistema Plc e l’upgrade dei circuiti funzionali."}
            />
            <Row className="justify-content-center">
                <Col xs={12} md={4} className="mb-4 mb-md-0">
                <img
                    src={awf1}
                    alt=""
                    className="img-fluid w-100 h-100 object-fit-cover"
                    style={{ aspectRatio: "1/1" }}
                />
                </Col>
                <Col xs={12} md={4} className="mb-4 mb-md-0">
                <img
                    src={awf2}
                    alt=""
                    className="img-fluid w-100 h-100 object-fit-cover"
                    style={{ aspectRatio: "1/1" }}
                />
                </Col>
                <Col xs={12} md={4} className="mb-4 mb-md-0">
                <img
                    src={awf3}
                    alt=""
                    className="img-fluid w-100 h-100 object-fit-cover"
                    style={{ aspectRatio: "1/1" }}
                />
                </Col>
            </Row>
        </Container>
    );
}