import React from 'react';
import { Container, Row, Col, Card, ListGroup, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRecycle, faWifi, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'; // Importa le icone desiderate

const PlcPage = () => {
    return (
    <Container className="my-5">
        <Row className="my-4">
            <Col>
                <p className='lead mb-0 text-center opacity-75'>
                    Il revamping dei PLC esistenti è uno dei traguardi più alti che la nostra azienda potesse generare. Ci sentiamo di affermarlo per tre motivi principali:
                </p>
            </Col>
        </Row>

        <Row className="d-flex flex-wrap justify-content-between">
        <Col xs={12} lg={4} className="d-flex flex-column justify-content-start align-items-center rounded-2 p-5">
            <div className="text-center">
                <FontAwesomeIcon icon={faRecycle} className="textc-accent fw-bold mb-2 display-4" />
                <p className="lead mt-2">Rigenerazione</p>
            </div>
            <p className="small text-center opacity-75">
                Rigenerazione di una risorsa importante con conseguente abbattimento di sprechi.
            </p>
            </Col>

            <Col xs={12} lg={4} className="d-flex flex-column justify-content-start align-items-center rounded-2 p-5">
            <div className="text-center">
                <FontAwesomeIcon icon={faWifi} className="textc-accent fw-bold mb-2 display-4" />
                <p className="lead mt-2">Controllo Remoto</p>
            </div>
            <p className="small text-center opacity-75">
                Impianto dotato di un controllo quasi totale da remoto con conseguente annullamento del fermo impianto.
            </p>
            </Col>

            <Col xs={12} lg={4} className="d-flex flex-column justify-content-start align-items-center rounded-2 p-5">
            <div className="text-center">
                <FontAwesomeIcon icon={faExclamationTriangle} className="textc-accent fw-bold mb-2 display-4" />
                <p className="lead mt-2">Controllo Criticità</p>
            </div>
            <p className="small text-center opacity-75">
                Controllo capillare di ogni criticità, con allarmi dedicati per ogni fase di funzionamento dell’impianto.
            </p>
            </Col>
        </Row>

        <Row className="mb-4">
        <Col>
            <h2 className='display-5 fw-bold'>Il Revamping PLC</h2>
            <p className='lead opacity-75'>
                Il revamping PLC, oltre a dare un refresh all’impianto ed allinearlo ad una visione 4.0 con digitalizzazione di un impianto degli anni '90, strizza l’occhio all’ambiente grazie alla possibilità di abbattere sprechi d’acqua, controllare le temperature e prevenire potenziali guasti di sistema.<br></br>Il revamping PLC da solo non è sufficiente per ottenere un impianto ad altissima efficienza. Gli upgrade dei circuiti permettono il dialogo tra il nuovo PLC e componenti essenziali come:
            </p>
            <ul>
                <li className='lead opacity-75'>Pompe dosatrici prodotti</li>
                <li className='lead opacity-75'>Macchina della produzione</li>
                <li className='lead opacity-75'>Sistema addolcitore</li>
            </ul>
        </Col>
        </Row>

        <Row className="mb-4">
            <Col>
                <h4 className='display-6 fw-bold'>Caratteristiche Tecniche dell'Upgrade</h4>
            </Col>
        </Row>

        <Row className="mb-4">
            <Col>
            <h4>Rilevamento in Tempo Reale</h4>
            <p className='lead opacity-75'>Conducibilità e durezza dell’acqua rilevabili in tempo reale.</p>
            </Col>
        </Row>

        <Row className="mb-4">
            <Col>
            <h4>Controllo da Remoto</h4>
            <p className='lead opacity-75'>Gestione completa dell’impianto tramite controllo remoto.</p>
            </Col>
        </Row>

        <Row className="mb-4">
            <Col>
            <h4>Modularità</h4>
            <p className='lead opacity-75'>Possibilità di adattare l’impianto alle esigenze del cliente.</p>
            </Col>
        </Row>

        <Row className="mb-4">
            <Col>
                <h4>Il Futuro del Revamping</h4>
                <p className='lead opacity-75'>
                Il nostro reparto di ricerca ha sviluppato un programma PLC in grado di dialogare con le macchine, permettendo l'operatività da remoto. Il nostro obiettivo è prendere il miglior impianto autolavaggio self service degli anni '90 e portarlo nel futuro, rendendolo nuovamente leader per qualità ed efficienza.
                </p>
                <p className='lead opacity-75'>
                Ci fermeremo? Assolutamente no. Siamo pronti a continuare l'innovazione per garantire il massimo livello di efficienza e sostenibilità.
                </p>
            </Col>
        </Row>
    </Container>
    );
};

export default PlcPage;
